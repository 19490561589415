//* Initialise the table with the required column ordering data types */
$(document).ready(function() {
   document.cookie = 'previous_clicked' + '=' + false
   document.cookie = 'next_clicked' + '=' + false
  
  $('.data-table').dataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "autoWidth": false,
    "ajax": $('.data-table').data('source'),
    "pagingType": "simple_numbers",
    "pageLength": 50,
    oLanguage: {
      sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
    },
    columnDefs: [
      { orderable: false, targets: [-1,-2,-3] }
    ]
  });

  $('.data-table-three-buttons').dataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "autoWidth": false,
    "ajax": $('.data-table').data('source'),
    "pagingType": "simple_numbers",
    "pageLength": 50,
    oLanguage: {
      sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
    },
    columnDefs: [
      { orderable: false, targets: [-1,-2,-3] }
    ]
  });

  $('.data-table-four-buttons').dataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "autoWidth": false,
    "ajax": $('.data-table').data('source'),
    "pagingType": "simple_numbers",
    "pageLength": 50,
    oLanguage: {
      sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
    },
    columnDefs: [
      { orderable: false, targets: [-1,-2,-3,-4] }
    ]
  });

  $('.data-table-three-buttons').dataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "autoWidth": false,
    "ajax": $('.data-table').data('source'),
    "pagingType": "simple_numbers",
    "pageLength": 50,
    oLanguage: {
      sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
    },
    columnDefs: [
      { orderable: false, targets: [-1,-2,-3] }
    ]
  });

  $('.data-table-two-buttons').dataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "autoWidth": false,
    "ajax": $('.data-table').data('source'),
    "pagingType": "simple_numbers",
    "pageLength": 50,
    oLanguage: {
      sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
    },
    columnDefs: [
      { orderable: false, targets: [-1,-2] }
    ]
  });

  if($(".giftcard-data-table-two-buttons").length > 0){
    $('.giftcard-data-table-two-buttons').dataTable({
      "processing": true,
      "serverSide": true,
      "responsive": true,
      "autoWidth": false,
      "ajax": $('.data-table').data('source'),
      "pagingType": "simple_numbers",
      "lengthMenu": [ 10, 25, 50, 100, 300 ],
      "pageLength": 50,
      "order": [Array.prototype.indexOf.call(document.getElementById('order-date').parentNode.children , document.getElementById('order-date')), 'desc'],
      oLanguage: {
        sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
      },
      columnDefs: [
        { orderable: false, targets: ['no-sort'] }
      ]
    });
  }

  $('.data-table-one-buttons').dataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "autoWidth": false,
    "ajax": $('.data-table').data('source'),
    "pagingType": "simple_numbers",
    "pageLength": 50,
    oLanguage: {
      sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
    },
    columnDefs: [
      { orderable: false, targets: [-1] }
    ],
    initComplete: function () {
      this.api().columns().each( function () {
        var column = this;
        var select = $('<select><option value=""></option></select>')
            .appendTo( $(column.footer()).empty() )
            .on( 'change', function () {
                var val = $.fn.dataTable.util.escapeRegex(
                    $(this).val()
                );
                column
                    .search( val ? '^'+val+'$' : '', true, false )
                    .draw();
            } );
        column.data().unique().sort().each( function ( d, j ) {
            select.append( '<option value="'+d+'">'+d+'</option>' )
        } );
      } );
    }
  });

  $('.user-datatable').dataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "autoWidth": false,
    "ajax": $('.data-table').data('source'),
    "pagingType": "simple",
    "pageLength": 50,
    "info":     false,
    "lengthChange": false,
    oLanguage: {
      sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
    },
       drawCallback: function(response){
          var api = this.api();
          if (response.json.meta['current_page_no'] == null ) {
            var ele = "<li><a>" + 1 + "</a><li>"
          } else {
            var ele = "<li><a>" + (parseInt(response.json.meta['current_page_no'])) + "</a><li>"
          }
          $(ele).insertAfter("li.paginate_button.previous");
          if (parseInt(response.json.meta['current_page_no']) == 1) {
            $('.paginate_button.previous').addClass('disabled')
          }
          else {
            $('.paginate_button.previous').removeClass('disabled')
          }
        $('.paginate_button.next').removeClass('disabled')
        $('.paginate_button.previous:not(.disabled)', this.api().table().container())          
        .on('click', function(){
            document.cookie = 'previous_clicked' + '=' + true
            document.cookie = 'next_clicked' + '=' + false
        });
        $('.paginate_button.next:not(.disabled)', this.api().table().container())
        .on('click', function(){
          document.cookie = 'previous_clicked' + '=' + false
          document.cookie = 'next_clicked' + '=' + true
          // $('.paginate_button.previous').addClass('disabled')
        });
   
   },

    columnDefs: [
      { orderable: false, targets: [-1, -3] },
      {
        "render": function ( data, type, row,meta ) {
          var current_page_no = JSON.stringify( meta.settings.json.meta['current_page_no'] )
          document.cookie = 'pagination_obj_' + current_page_no + '=' + JSON.stringify( meta.settings.json.meta )
          document.cookie = 'current_page_no' + '=' + current_page_no
          if (data != null) {
            return "<a class='btn btn-info btn-sm' href='/admin/users/" + data + "'><i class='fa fa-eye'></i></a>";
          }
          else{
            return '';
          }
        },
        "targets": -1
      },
      {
        "render": function ( data, type, row ) {
          if(data == true){
            return "<i class='fa fa-check-circle fa-1.5x text-info'></i></span>";
          }
          else
          {  
            return "<i class='fa fa-times-circle fa-1.5x text-danger'></i></span>";
          }
        },
        "targets": -3
      },
    ],
    initComplete: function () {
      this.api().columns().each( function () {
        var column = this;
        var select = $('<select><option value=""></option></select>')
            .appendTo( $(column.footer()).empty() )
            .on( 'change', function () {
                var val = $.fn.dataTable.util.escapeRegex(
                    $(this).val()
                );
                column
                
                    .search( val ? '^'+val+'$' : '', true, false )
                    .draw();
            } );
        column.data().unique().sort().each( function ( d, j ) {
            select.append( '<option value="'+d+'">'+d+'</option>' )
        } );
      } );
    }
  });


  $('.data-table-no-buttons').dataTable({
    "processing": true,
    "serverSide": true,
    "responsive": true,
    "autoWidth": false,
    "ajax": $('.data-table').data('source'),
    "pagingType": "simple_numbers",
    "pageLength": 50,
    oLanguage: {
      sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
    }
  });

  if($(".order-datatable-v2").length > 0){
    $('.order-datatable-v2').dataTable({
      "processing": true,
      "serverSide": true,
      "responsive": true,
      "autoWidth": false,
      "dom": 'lrt<"bottom"p><"clear">',
      "pagingType": "simple",
      "order": [Array.prototype.indexOf.call(document.getElementById('order-date-default').parentNode.children , document.getElementById('order-date-default')), 'desc'],
      "ajax:": $('.data-table').data('source'),
      "pageLength": 50,
      oLanguage: {
        sProcessing: "<h4 class='text-center'><span class='text-success'>Loading... <i class='fa fa-spinner fa-1.5x fa-spin'></i></span></h4>"
      },
      columnDefs: [
        { orderable: false, targets: ['no-sort'] }
      ],
      drawCallback: function () {
        var api = this.api();
        var ele = "<li><a>" + (api.page.info().page + 1) + "</a><li>"
        $(ele).insertAfter("li.paginate_button.previous");
      },
      initComplete: function () {
        $('.new-order-dt thead tr')
          .clone(true)
          .addClass('filters')
          .appendTo('.new-order-dt thead');
          $(".filters .manual-sort").removeClass("sorting_desc sorting");
        var api = this.api();
        api
          .columns()
          .eq(0)
          .each(function (colIdx) {
            // Set the header cell to contain the input element
            if ($(cell).text() == "Order ID" || $(cell).text() == "Promo Code" || true) {
              var cell = $('.filters th').eq(
                $(api.column(colIdx).header()).index()
              );
              var title = $(cell).text();
              // if (colIdx == 1 || colIdx == 2 || title == 3) {
              if (title == 'Promo Code' || title == "Customer ID" || title == "Order ID" || title == "IP Address") {
                $(cell).html("<input type='text'/>");
              } else {
                $(cell).html('');
              }
              // On every keypress in this input
              $(
                'input',
                $('.filters th').eq($(api.column(colIdx).header()).index())
              )
                .off('keyup change')
                
                .on('keyup change', function (e) {
                  // Get the search value
                  $(this).attr('title', $(this).val());
                  var regexr = '{search}'; //$(this).parents('th').find('select').val();
                  var cursorPosition = this.selectionStart;
                  // Search the column for that value
                  if (e.key == 'Enter' || true) {
                    api
                      .column(colIdx)
                      .search(
                        this.value != ''
                          ? regexr.replace('{search}', this.value)
                          : '',
                        this.value != '',
                        this.value == ''
                      )
                      .draw();
                  }
                })
                .on('keyup_not_needed', function (e) {
                  e.stopPropagation();
                  $(this).trigger('change');
                  $(this).focus()[0].setSelectionRange(cursorPosition, cursorPosition);
                });
            }
            else {
              $(this).html()
            }
          });
      },
    });
  }


  $.fn.dataTable.ext.errMode = 'throw';

});
