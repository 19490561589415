import swal from "sweetalert2";
import Rails from "@rails/ujs";
import Swal from 'sweetalert2';
window.Swal = Swal;

Rails.confirm = function(message, element) {
    var sweetAlertConfirmConfig = sweetAlertConfirmConfig || {}; // Add default config object
    Swal.fire({
        icon: 'warning',
        showCancelButton: sweetAlertConfirmConfig.showCancelButton || true,
        type: sweetAlertConfirmConfig.type || 'warning',
        title: message || 'Are you sure?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: sweetAlertConfirmConfig.confirmButtonText || "Ok",
        cancelButtonText: sweetAlertConfirmConfig.cancelButtonText || "Cancel",
        // width: '64em',
        heightAuto: false, 
    }).then((result) => {
        if (result.value) {
            console.log("sweetalert finished");
            element.removeAttribute("data-confirm");
            element.click();
        }
    })
};
