// Sidebar collapse after refresh
$(document).ready(function() {
	// Sidebar collapse after refresh
	var pathname = window.location.pathname;
	var element = $('#side-menu a').filter(function() {
		var singleList = ['/admin/users/import_jobs']
		if (this.pathname === pathname) {
			return true
		}else if(pathname.indexOf(this.pathname) != -1 && this.pathname.length > 1 && !singleList.includes(pathname)){
			return true
		}
	})
	if (pathname == '/dashboard') {
	  $("#maindashboard").addClass('active').parent().addClass('active');
	}else if (element.length > 0) {
		// Third level scroll
		if(element.parent().parent().hasClass('nav nav-third-level')){
			element.addClass('active').parent().addClass('active').parent().parent().addClass('active').parent().parent().addClass('active')
			$(".navbar-static-side").delay(500).animate({ scrollTop: element.parent().parent().parent().offset().top - 500 }, 1500);
			// Second level scroll
		}else{
			element.addClass('active').parent().addClass('active').parent().parent().addClass('active')
			$(".navbar-static-side").delay(500).animate({ scrollTop: element.parent().offset().top - 500 }, 1500);
		}
	};
});
